<template>
  <v-container fluid>
    <v-main>
      <!-- <keep-alive> -->
      <v-card outlined>
        <v-flex>
          <component class="mb-10 mt-1"
                  v-bind:is="currentTabComponent" 
                  :canada="canada" 
                  :service="service" 
                  :services="services" 
                  :assigntolocation="assigntolocation" 
                  :assigntodistrict="assigntodistrict" 
                  :requestpickup="requestpickup" 
                  :recycleplus="recycleplus" 
                  :bulkitempickup="bulkitempickup" 
                  :stopservice="stopservice" 
                  :phone="phone" 
                  :lang="lang" 
                  :light="light" 
                  :terms="terms" 
                  :privacy="privacy" 
                  :map="map" 
                  :pay="pay" 
                  :name="name" 
                  :pickupscheduleurl="pickupscheduleurl" 
                  :faq="faq" 
                  :seasonvacationphone="seasonvacationphone" 
                  :hideTocOptions="hideTocOptions" 
                  :hideSomethingElse="hideSomethingElse">
          </component>
        </v-flex>
      </v-card>
      <!-- <v-scroll-y-reverse-transition>
        <HandlingError class="ma-0" :errorAxios="errorAxios"></HandlingError>
      </v-scroll-y-reverse-transition> -->
      <!-- <v-card outlined>
        <v-alert class="grey lighten-3 ma-0" elevation ="10">
          <v-flex class="lg12 xl12">
            <CustomerServiceBar class="py-0" :phone="phone"></CustomerServiceBar>
          </v-flex>
        </v-alert>
      </v-card> -->
    </v-main>
  </v-container>

</template>

<script>
import { mapWritableState, mapActions } from 'pinia';
import { useIndexStore } from '../../store/index';
import { useContactUsStore } from '@/store/contactus';
import CustomerServiceBar from "@/components/requestquote/footer/CustomerServiceBar";
import ContactWidgetV3 from '@/components/contactus/ContactWidgetV3.vue';
import ContactWidget from '@/components/contactus/ContactWidget'
import salesforce from '../../plugins/salesforce';

export default {
  name: "MainPage",
  props: {
        canada: Boolean,
        service: String,
        services: String,
        assigntolocation: Number,
        assigntodistrict: Number,
        bulkitempickup: String,
        requestpickup: Boolean,
        recycleplus: Boolean,
        stopservice: Boolean,
        phone: String,
        lang: String,
        light: Boolean,
        terms: String,
        privacy: String,
        map: String,
        pay: String,
        name: String,
        pickupscheduleurl: String,
        faq: String,
        hideTocOptions: String,
        seasonvacationphone: Boolean,
        hideSomethingElse: Boolean,
  },

  components: {
    ContactWidgetV3,CustomerServiceBar,ContactWidget
  },
    created(){
        // this.bulkItemPickupPinia = this.bulkItemPickup;
        // this.payPinia = this.pay;
    },
    mounted: function(){
      salesforce.campaignStorageTracking();
      if(this.contactUsServiceOnly()&&this.service){
          this.viewForm(this.service.toLowerCase(), true);
          this.updateViewComponent("ContactWidget", true);
      }
    },
    computed: {
      ...mapWritableState(useIndexStore, [
        "component","currentTabComponent",
        ]),
        ...mapWritableState(useContactUsStore, [
        "view",
        ]),
    },
    methods:{
        ...mapActions(useIndexStore, [
              "updateViewComponent",
          ]),
        ...mapActions(useContactUsStore, [
              "viewForm","contactUsServiceOnly"
          ])
    }
  }
</script>

<style scoped>
::v-deep .box-shadow-1 {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.15) !important;
}
::v-deep .green-svg {
  fill: #175e45 !important;
}
::v-deep .white-svg {
  fill: white !important;
}
::v-deep .blue-svg {
  fill: #1f528f !important;
}
::v-deep .grey-svg {
  fill: #4E4E4E !important;
}
::v-deep .blue-default-svg {
  background-color: #F0F7FE !important;
}
::v-deep .blue-active-svg {
  background-color: #1F528F !important;
}
::v-deep .blue-hover-svg {
  background-color: #DAE7F5 !important;
  color:black !important;
}
::v-deep .green-default-svg {
  background-color: #E9F4F0 !important;
}
::v-deep .green-active-svg {
  background-color: #175E45 !important;
}
::v-deep .green-hover-svg {
  background-color: #DCEAE5 !important;
  color:black !important;
}

::v-deep .business-text {
  color:#1F528F !important;
}
::v-deep .residential-text {
  color:#175e45 !important;
}
::v-deep .grey-text {
  color:#4E4E4E !important;
}
::v-deep .grey-title-text {
  color:#8D8D8D !important;
}
::v-deep .searchbar.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

</style>
