<template>
    <div>
        <v-row v-if="servicefromquote&&$vuetify.breakpoint.mobile&&!canada" class="mt-3 ml-3 justify-center">
            <v-col align-self="center" class="pa-2 pt-1">
                <BackBtn currentTabComponent="ContactWidget"></BackBtn>
            </v-col>
        </v-row>
        <v-row v-if="!$vuetify.breakpoint.mobile&&!canada" class="mt-3 justify-center">
            <v-col v-if="servicefromquote" cols="auto" align-self="center" class="pa-2 pt-1">
                <BackBtn currentTabComponent="ContactWidget"></BackBtn>
            </v-col>
            <v-col class="pa-2" cols="12" md="10" lg="6">
                <v-text-field
                    v-model="search"
                    class="searchbar rounded-pill mx-3"
                    height="47px"
                    :append-icon="!search ? 'mdi-magnify' : ''"
                    :label="$t('search')+'...'"
                    solo
                    single-line
                    flat
                    outlined
                    clear-icon="mdi-close-circle"
                    clearable
                    @click:clear="search = ''"
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>

        <div v-if="canada">
            <v-card-text v-if="view.index&&(canada?!form.type:false)">
                <div class="text--primary text-center">
                    {{ $t('header.instructions.formTypeFalse') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.index&&(canada?form.type:true)">
                <div class="text--primary text-center">
                    {{ $t('header.instructions.formTypeTrue') }}
                </div>
            </v-card-text>
            <v-row v-if="canada" class="pa-2">
                <v-col>
                    <v-item-group :class="$vuetify.breakpoint.width < 650 ? '' : 'pa-0 mt-4 min-height-22 d-flex justify-center'" v-model="form.type">
                        <v-item v-for="(serviceType, index) in servicesType" :key="serviceType.name" :value="serviceType.leadType">
                            <template v-slot="{ active }">
                                <v-hover v-slot="{hover}">
                                    <v-card :dark="active?true:false" class="rounded-lg mb-2" :outlined="form.type ? false : active ? false : true" :width="$vuetify.breakpoint.width < 650 ? '100%' : '29%'" :class="[serviceType.name =='CommercialService' ? classSvgBusiness(active,hover) : classSvgResidential(active,hover), index===0?'mr-sm-2 mr-0':'ml-sm-2 ml-0']" @click="form.type = serviceType.leadType; search = ''">
                                        <!-- Selected/Clicked icon section -->
                                        <v-col>
                                            <v-row align="center" justify="center">
                                                <iconRequestQuote :svg="serviceType.icon" class="pa-5" :class="serviceType.name =='CommercialService' ? fillSvgBusiness(active,hover) : fillSvgResidential(active,hover)"></iconRequestQuote>
                                                <span class="text-subtitle-1 font-weight-bold pt-0 text-break" v-html="$t(serviceType.text)"></span>
                                            </v-row>
                                        </v-col>
                                    </v-card>
                                </v-hover>
                            </template>
                        </v-item>
                    </v-item-group>
                </v-col>
            </v-row>
        </div>

        <v-row v-if="!$vuetify.breakpoint.mobile&&canada&&form.type" class="justify-center">
            <v-col class="mt-10 mb-2 py-0" :cols=" $vuetify.breakpoint.width > 768 && $vuetify.breakpoint.width < 1200 ? 10 : $vuetify.breakpoint.width < 768 ? 12 : 6">
                <v-text-field
                    v-model="search"
                    class="searchbar rounded-pill mx-3"
                    height="47px"
                    :append-icon="!search ? 'mdi-magnify' : ''"
                    :label="$t('search')+'...'"
                    solo
                    single-line
                    flat
                    outlined
                    clear-icon="mdi-close-circle"
                    clearable
                    @click:clear="search = ''"
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col v-if="!filteredCommunications.length && !filteredServices.length && !filteredOtherServices.length">
                <div class="text-center text-body-1">{{$t('contactus.menu.error.noCustomer')}}</div>
            </v-col>
        </v-row>
        <v-list>
            <v-col v-if="canada?form.type:true">
                <v-list-item-group>
                    <v-row :class="!filteredCommunications.length && !filteredServices.length && !filteredOtherServices.length ? 'justify-center':''">
                        <v-col v-if="form.type !== 'Residential'" :cols="$vuetify.breakpoint.width < 1200 ? $vuetify.breakpoint.width < 768 ? 12 : 6 : 4">
                            <v-card-text v-if="!search && form.type !== 'Residential'" class="text-h6 grey-title-text text-center my-3">{{ i18n.t('service') }}</v-card-text>
                            <div v-for="(item,i) in filteredCommunications" :key="i">
                                <v-list-item v-if="(item.type == 'Residential') || (form.type == item.type || form.type == 'Commercial')" class="box-shadow-1 pa-0 mb-3" @click="updateViewComponent('ContactWidget');view.name = item.viewform;">
                                    <v-container @click="updateViewComponent('ContactWidget');view.name = item.viewform;" fluid fill-height>
                                        <v-layout>
                                            <v-flex class="shrink d-flex blue-default-svg">
                                                <v-list-item-avatar class="ml-4 my-4" :class=" $vuetify.breakpoint.lgAndUp ? 'pa-1' : 'pa-2'" width="32">
                                                    <icon class="blue-svg" :svg="item.icon"/>
                                                </v-list-item-avatar>
                                            </v-flex>
                                            <v-flex class="ml-3 d-flex align-center">
                                                <span style="text-overflow: initial!important; white-space: initial!important;" class="align-center justify-center text-break business-text font-weight-bold text-body-1">{{$t(item.title)}}</span>
                                            </v-flex>
                                            <div class="d-flex align-center mr-1">
                                                <v-tooltip attach max-width="250px" left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click.stop v-bind="attrs" v-on="on">
                                                                <icon class="blue-svg" svg="information" />
                                                            </v-btn>
                                                        </template>
                                                        <span>{{$t(item.description)}}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-layout>
                                    </v-container>
                            </v-list-item>
                            </div>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.width < 1200 ? $vuetify.breakpoint.width < 768 ? 12 : 6 : form.type !== 'Residential'?4:6">
                            <v-card-text v-if="!search" class="text-h6 grey-title-text text-center my-3">{{ i18n.t('account') }}</v-card-text>

                            <div v-for="(item,i) in filteredServices" :key="i">
                                <v-list-item v-if="(item.type == 'Residential') || (form.type == item.type || form.type == 'Commercial')" class="box-shadow-1 pa-0 mb-3" @click="updateViewComponent('ContactWidget');view.name = item.viewform;">
                                    <v-container @click="updateViewComponent('ContactWidget');view.name = item.viewform;" fluid fill-height>
                                        <v-layout>
                                            <v-flex class="shrink d-flex green-default-svg">
                                                <v-list-item-avatar class="ml-4 my-4" :class=" $vuetify.breakpoint.lgAndUp ? 'pa-1' : 'pa-2'" width="32">
                                                    <icon class="green-svg" :svg="item.icon"/>
                                                </v-list-item-avatar>
                                            </v-flex>
                                            <v-flex class="ml-3 d-flex align-center">
                                                <span style="text-overflow: initial!important; white-space: initial!important;" class="align-center justify-center text-break residential-text font-weight-bold text-body-1">{{$t(item.title)}}</span>
                                            </v-flex>
                                            <div class="d-flex align-center mr-1">
                                                <v-tooltip attach max-width="250px" left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click.stop v-bind="attrs" v-on="on">
                                                                <icon class="green-svg" svg="information" />
                                                            </v-btn>
                                                        </template>
                                                        <span>{{$t(item.description)}}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-layout>
                                    </v-container>
                                </v-list-item>
                            </div>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.width < 768 ? 12 :(!filteredServices.length || !filteredCommunications.length) && ($vuetify.breakpoint.width > 768 && $vuetify.breakpoint.width < 1200) ? 6 :(!filteredServices.length || !filteredCommunications.length) && $vuetify.breakpoint.width > 1200 ? 4 :(filteredServices.length && $vuetify.breakpoint.width > 1200) || (filteredCommunications.length && $vuetify.breakpoint.width > 1200) ? form.type !== 'Residential'?4:6 : ''">
                            <v-card-text v-if="!search" class="text-h6 grey-title-text text-center my-3">{{ i18n.t('serviceOptions.other') }}</v-card-text>
                            <div v-for="(item,i) in filteredOtherServices" :key="i">
                                <v-list-item v-if="(item.type == 'Residential') || (form.type == item.type || form.type == 'Commercial')" class="box-shadow-1 pa-0 mb-3" @click="updateViewComponent('ContactWidget');view.name = item.viewform;">
                                    <v-container @click="updateViewComponent('ContactWidget');view.name = item.viewform;" fluid fill-height>
                                        <v-layout>
                                            <v-flex class="shrink d-flex grey-default-bg">
                                                <v-list-item-avatar class="ml-4 my-4" :class=" $vuetify.breakpoint.lgAndUp ? 'pa-1' : 'pa-2'" width="32">
                                                    <icon class="grey-active-svg" :svg="item.icon"/>
                                                </v-list-item-avatar>
                                            </v-flex>
                                            <v-flex class="ml-3 d-flex align-center">
                                                <span style="text-overflow: initial!important; white-space: initial!important;" class="align-center justify-center text-break grey-text font-weight-bold text-body-1">{{$t(item.title)}}</span>
                                            </v-flex>
                                            <div class="d-flex align-center mr-1">
                                                <v-tooltip attach max-width="250px" left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon @click.stop v-bind="attrs" v-on="on">
                                                                <icon class="grey-svg" svg="information" />
                                                            </v-btn>
                                                        </template>
                                                        <span>{{$t(item.description)}}</span>
                                                </v-tooltip>
                                            </div>
                                        </v-layout>
                                    </v-container>
                                </v-list-item>
                            </div>
                        </v-col>
                    </v-row>
                </v-list-item-group>
            </v-col>
        </v-list>
        <v-row v-if="($vuetify.breakpoint.mobile&&!canada)||($vuetify.breakpoint.mobile&&canada&&form.type)" class="justify-center">
            <v-col class="mt-10 mb-2 py-0" :cols="$vuetify.breakpoint.width > 768 && $vuetify.breakpoint.width < 1200 ? 10 : $vuetify.breakpoint.width < 768 ? 12 : 6">
                <v-text-field
                    v-model="search"
                    class="searchbar rounded-pill mx-3"
                    height="47px"
                    :append-icon="!search ? 'mdi-magnify' : ''"
                    :label="$t('search')+'...'"
                    solo
                    single-line
                    flat
                    outlined
                    clear-icon="mdi-close-circle"
                    clearable
                    @click:clear="search = ''"
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="!hideSomethingElse" align="center" justify="center">
            <v-col>
                <v-card elevation="0" class="rounded-xl mb-3 mt-2">
                    <v-row>
                        <v-col>
                            <v-card-text class="text-center black--text text-h6 font-weight-medium pb-0">{{$t('contactus.menu.question.somethingElse')}}
                            </v-card-text>
                            <v-card-text class="text-center text-body-1 py-0">{{$t('contactus.menu.talkToRepresentative')}}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center py-0">
                            <v-hover v-slot="{hover}">
                                <v-btn :href="'tel:'+phone" elevation="0" :width="$vuetify.breakpoint.width < 1200 ? $vuetify.breakpoint.width < 768 ? '70%' : (!filteredServices.length || form.type == 'Residential') && $vuetify.breakpoint.width > 768 ? '70%': '50%':'25%'" height="60" style="border: 1px solid #00568C;" class="text-h6 rounded-lg" :class="hover?'blue-active-svg':''" >
                                    <icon svg="phone" :class="hover?'white-svg':'blue-hover-fill-svg'"></icon><span class="pl-4" :class="hover?'white--text ':'blue-default-text'">{{phone}}</span>
                                </v-btn>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-row class="justify-center">
                        <v-card elevation="0" :width="$vuetify.breakpoint.width < 1200 ? $vuetify.breakpoint.width < 768 ? '80%' : '45%':'30%'" >
                        <!-- <v-card-text class="text-body-2 text-center">{{$t('contactus.menu.phoneWaitingTime')}}</v-card-text> -->
                        </v-card>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { useContactUsStore } from '@/store/contactus';
import { useIndexStore } from '@/store/index';
import { mapState, mapWritableState, mapActions } from 'pinia';
import icon from "./assets/SvgLoader";
import iconRequestQuote from "@/components/requestquote/utility/SvgLoader.vue"
import { useUserStore } from '@/store/user';
import i18n from '@/i18n'
import BackBtn from "../requestquote/utility/backBtn.vue"
    export default {
        name: 'ContactWidgetV3',
        components: {icon,iconRequestQuote,BackBtn},
        props:{
        canada: Boolean,
        service: String,
        services: String,
        assigntolocation: Number,
        assigntodistrict: Number,
        bulkitempickup: String,
        requestpickup: Boolean,
        recycleplus: Boolean,
        stopservice: Boolean,
        phone: String,
        lang: String,
        light: Boolean,
        terms: String,
        privacy: String,
        map: String,
        pay: String,
        name: String,
        pickupscheduleurl: String,
        faq: String,
        seasonvacationphone: Boolean,
        hideSomethingElse: Boolean,
        },
    data() {
        return{
            filteredCommunications: "",
            filteredServices: "",
            filteredOtherServices: "",
            i18n: i18n,
            };
    },
    created(){
        // **************************************************
        // BIND PROPS TO PINIA TO USE VARIABLES IN PINIA STORE
        // **************************************************
        // Check what services to remove first before showing the list
        if(this.services.length){
            this.servicesCU = this.services;
            this.serviceItems;
        }
        if(this.service){
            this.serviceCU = this.service;
        }
        if(this.pay){
            this.payCU = this.pay;
        }
        if(this.assigntolocation){
            this.assigntolocationCU = this.assigntolocation;
        }
        if(this.canada){
            this.canadaCU = this.canada
        }
        if(this.lang){
            this.langCU = this.lang;
        }
        if(this.bulkitempickup){
            this.bulkitempickupCU = this.bulkitempickup
        }
        if(this.seasonvacationphone){
            this.seasonvacationphoneCU = this.seasonvacationphone;
        }

        // Check if pickupschedule props have a value,
        // assign url/value to the "Pickup Schedule" Card
        if(this.pickupscheduleurl){

            // Get data from props pickup schedule and set the url in the object if it exists
            for (let index = 0; index < this.otherServices.length; index++) {
                if(this.otherServices[index].title == this.i18n.t('pickupSchedule.title')){
                    this.otherServices[index].url = this.pickupscheduleurl
                    this.pickupscheduleurlCU = this.otherServices[index].url
                }
            }
        }

        // Check if faq props have a value,
        // assign url/value to the "FAQs" Card
        if(this.faq){

            // Get data from props faq and set the url in the object if it exists
            for (let index = 0; index < this.otherServices.length; index++) {
                if(this.otherServices[index].title == this.i18n.t('FAQs')){
                    this.otherServices[index].url = this.faq
                    this.faqCU = this.otherServices[index].url;
                }
            }
        }
        // Check if prop of "stopservice" is set,
        // Change "Cancel Service" form to "Stop Service"
        if(this.stopservice){
            for (let index = 0; index < this.servicesOther.length; index++) {
                if(this.i18n.t(this.servicesOther[index].title) == this.i18n.t('serviceOptions.cancelService')){
                    this.servicesOther[index].title = 'serviceOptions.stopService'
                    this.servicesOther[index].description = 'description.stopService'
                    this.servicesOther[index].view = 'StopService'
                    this.servicesOther[index].viewform = 'stopService'
                }
            }
        }

        // Initiate a copy of the objects so it can be modifiable after checking for props changes
        this.filteredCommunications = this.communications;
        this.filteredServices = this.servicesOther;
        this.filteredOtherServices = this.otherServices;

        // Hide/remove journeys when props is "true"
        if(!this.requestpickup){
            this.requestpickupCU = this.requestpickup;
            this.filteredOtherServices = this.otherServices.filter((item) => { return this.i18n.t(item.title) !== this.i18n.t('requestPickup.title') });

        }
        if(!this.recycleplus){
            this.recycleplusCU = this.recycleplus;
            this.filteredServices = this.servicesOther.filter((item) => { return this.i18n.t(item.title) !== this.i18n.t('recyclePlus.title') });
            this.servicesOther = this.filteredServices;
            }

        // Assign languages from props
        this.$i18n.locale = this.lang;
    },
    mounted: function(){
        this.$i18n.locale = this.lang;
    },
    watch:{
        "search":function(newVal,oldVal) {
            if(newVal !== oldVal && this.search){
                    this.filteredCommunications = this.communications.filter((item) => {
                        return (this.i18n.t(item.title).toLowerCase().includes(this.search.toLowerCase()) || this.i18n.t(item.description).toLowerCase().includes(this.search.toLowerCase()) || this.i18n.t(item.keywords).toLowerCase().includes(this.search.toLowerCase()))
                    })
                    this.filteredServices = this.servicesOther.filter((item) => {
                        return (this.i18n.t(item.title).toLowerCase().includes(this.search.toLowerCase()) || this.i18n.t(item.description).toLowerCase().includes(this.search.toLowerCase()) || this.i18n.t(item.keywords).toLowerCase().includes(this.search.toLowerCase()))
                    })
                    this.filteredOtherServices = this.otherServices.filter((item) => {
                        return (this.i18n.t(item.title).toLowerCase().includes(this.search.toLowerCase()) || this.i18n.t(item.description).toLowerCase().includes(this.search.toLowerCase()) || this.i18n.t(item.keywords).toLowerCase().includes(this.search.toLowerCase()))
                    })
            } else {
                this.filteredCommunications = this.communications;
                this.filteredServices = this.servicesOther;
                this.filteredOtherServices = this.otherServices;
            }

        }
    },
    computed: {
        // Map state properties as readonly computed properties
        ...mapState(useContactUsStore, ["communications","view"
        ]),
        ...mapWritableState(useContactUsStore, [
            "selectedId","servicesOther","view","otherServices",'bulkitempickupCU',"pickupscheduleurlCU","faqCU",'servicesCU', "assigntolocationCU", "canadaCU", "langCU","stopserviceCU","search","serviceItems","serviceCU","seasonvacationphoneCU"]),
        ...mapWritableState(useIndexStore, [
            "component","servicesType","servicefromquote"
        ]),
        ...mapWritableState(useUserStore, [
            "form"
        ]),

    },
    methods:{
        ...mapActions(useContactUsStore,[
            "viewStep","contactUsServiceOnly","viewForm"
        ]),
        ...mapActions(useIndexStore,
        [
            "updateViewComponent",
            "scrollToTop",
            "classSvgResidential",
            "classSvgBusiness",
            "fillSvgResidential",
            "fillSvgBusiness",
        ])
    }
}
</script>
<style scoped>
::v-deep .v-tooltip__content.menuable__content__active {
    background: rgb(50, 50, 50, 1) !important;
}
::v-deep .green-default-svg {
    background-color: #E9F4F0 !important;
}
::v-deep .green-svg {
    fill: #175E45 !important;
}
::v-deep .blue-default-svg {
    background-color: #F0F7FE !important;
}
::v-deep .blue-active-svg {
    background-color: #1F528F !important;
}
::v-deep .blue-svg {
    fill:#1F528F !important;
}
::v-deep .blue-hover-fill-svg{
    fill:#153F70 !important;
}
::v-deep .grey-active-svg{
    fill: #4E4E4E !important;
}
::v-deep .grey-default-bg{
    background-color: #EDEDED !important;
}
::v-deep .inactive-svg {
    background-color: #EBEBEB !important;
}
::v-deep .white-svg {
    fill: white !important;
}
::v-deep .blue-default-text{
    color:#153F70 !important;
}
::v-deep .title-label {
    color:#4E4E4E;
}
::v-deep .v-avatar {
    border-radius: initial;
}
/* ::v-deep .mdi-magnify::before {
    background-color: grey;
    padding: 10px;
    border-radius: 25px;
    content: "\F0349";
    width: 70px;
    height: 47px;
    text-align: center;
    color: white;
} */
/* ::v-deep .mdi-close-circle::before {
    background-color: grey;
    padding: 10px;
    border-radius: 25px;
    content: "\F0159";
    width: 70px;
    height: 47px;
    text-align: center;
    color: red;
} */
</style>